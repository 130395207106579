import { Grid, List } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "components/common";
import LABEL from "constants/label";
import CONFIG from "constants/config";
import PropTypes from "prop-types";
import "./styles.scss";
import OutlinedButton from "components/common/Button/OutlinedButton/OutlinedButton";
import ButtonWithText from "components/common/Button/ButtonWithText/ButtonWithText";
import ConfirmationModal from "components/common/Modal/ConfirmationModal/ConfirmationModal";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import { setSkipForNow } from "redux/actions/profileCompletion";
import { isEmptyOrNull, toastError } from "helpers/utils";

const {
  PROFILE_OVERVIEW: {
    UpdateNow,
    SkipForNow,
    YelloExclamationIcon,
    RedCrossIcon,
    GreenTickIcon,
    ProfileConfirmation,
    SkipForNowPopupMessage
  },
  COMMON: { NA }
} = LABEL;

const { OVERVIEW_EDIT } = EVENTS;
const {
  PROFILE_COMPLETION: { UPDATE_NOW, SKIP_FOR_NOW }
} = TRIGGERS;
const { apiUrl, xApiKey, avgProfileViews, avgCvBioDownloads } = CONFIG;

const ProfileCompletionRow = ({
  isCompleted,
  lastUpdateDate,
  listData,
  rowName,
  isSectionNotCompleted,
  testId,
  onUpdate,
  isVisitorView,
  skipForNowClicked
}) => {
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [isSkipForNowClicked, setSkipForNowCliked] = useState(false);
  const dispatch = useDispatch();
  const { profile, skipForNowError, skipForNowLoading } = useSelector((state) => state);
  const viewCount = Number(avgProfileViews).toLocaleString("en-US");
  const downloadCount = Number(avgCvBioDownloads).toLocaleString("en-US");

  const onClickSkipForNow = () => {
    setSkipForNowCliked(true);
    dispatch(
      setSkipForNow({
        endpoint: `${apiUrl.linkApi}profile/${profile.hrId}/skip/${rowName === "About" ? "about" : "summary"}/true`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  };

  useEffect(() => {
    if (isSkipForNowClicked && !skipForNowLoading) {
      if (isEmptyOrNull(skipForNowError)) {
        setShowButtons(false);
      } else {
        toastError();
      }
      setSkipForNowCliked(false);
    }
  }, [skipForNowError, skipForNowLoading]);

  const handleOnUpdateNowClick = () => {
    onUpdate();
    window.$tabName = OVERVIEW_EDIT;
    sendEventData(UPDATE_NOW, OVERVIEW_EDIT, {
      subCategory: ProfileConfirmation,
      sortOrder: NA
    }); // Analytics Event
  };
  const handleOnSkipForNowClick = () => {
    setOpenWarningPopup(true);
    window.$tabName = OVERVIEW_EDIT;
    sendEventData(SKIP_FOR_NOW, OVERVIEW_EDIT, {
      subCategory: ProfileConfirmation,
      sortOrder: NA
    }); // Analytics Event
  };

  const incompleteIcon = isVisitorView ? YelloExclamationIcon : RedCrossIcon;

  return (
    <>
      <Grid.Row className="profileCompletionRow" data-testid={testId}>
        <Grid.Column>
          <div>
            <Icon name={!isCompleted ? incompleteIcon : GreenTickIcon} size={16} />
          </div>
        </Grid.Column>
        <Grid.Column mobile={8} computer={14}>
          <div>
            <span>{rowName} </span>
            {lastUpdateDate || !isVisitorView ? <>| </> : null}
            {lastUpdateDate ? (
              <>
                Last updated on
                <span> {lastUpdateDate}</span>
              </>
            ) : (
              <>
                {!isVisitorView ? (
                  <button
                    type="button"
                    onClick={onUpdate}
                    className="cursor-pointer profileCompletionRow__addButton"
                  >{`Add ${rowName}`}</button>
                ) : null}
              </>
            )}
          </div>
          {!isVisitorView ? (
            <List bulleted>
              {listData?.map((item, index) => {
                return <List.Item key={item}>{item}</List.Item>;
              })}
            </List>
          ) : (
            <></>
          )}

          {isSectionNotCompleted && showButtons && !isVisitorView && !skipForNowClicked && lastUpdateDate && (
            <div>
              <OutlinedButton data-testid="UpdateNow" text={UpdateNow} onClick={handleOnUpdateNowClick} />
              <ButtonWithText data-testid="Skip" text={SkipForNow} onClick={handleOnSkipForNowClick} />
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
      {openWarningPopup && (
        <ConfirmationModal
          isOpen={openWarningPopup}
          modalMessage={SkipForNowPopupMessage(viewCount, downloadCount)}
          handleCancelClick={() => {
            setOpenWarningPopup(false);
            onUpdate();
          }}
          handleContinueClick={() => {
            setOpenWarningPopup(false);
            onClickSkipForNow();
          }}
        />
      )}
    </>
  );
};

ProfileCompletionRow.defaultProps = {
  isCompleted: false,
  lastUpdateDate: "",
  listData: [],
  rowName: "",
  isSectionNotCompleted: false,
  testId: ""
};

ProfileCompletionRow.propTypes = {
  isCompleted: PropTypes.bool,
  lastUpdateDate: PropTypes.string,
  rowName: PropTypes.string,
  listData: PropTypes.arrayOf(PropTypes.string),
  isSectionNotCompleted: PropTypes.bool,
  testId: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  skipForNowClicked: PropTypes.bool.isRequired,
  isVisitorView: PropTypes.bool.isRequired
};

export default ProfileCompletionRow;
