import { all, fork } from "redux-saga/effects";
import watchUser from "./watcher/watchUser";
import watchProfile from "./watcher/watchProfile";
import watchProfileImage from "./watcher/watchProfileImage";
import watchProfileImageHighRes from "./watcher/watchProfileImageHighRes";
import watchExperience from "./watcher/watchExperience";
import watchEducation from "./watcher/watchEducation";
import watchAchievements from "./watcher/watchAchievements";
import watchCases from "./watcher/watchCases";
import watchUpdateCaseResponsibilities from "./watcher/watchUpdateCaseResponsibilities";
import watchUpdateCase from "./watcher/watchUpdateCase";
import watchMaterials from "./watcher/watchMaterials";
import watchTopics from "./watcher/watchTopics";
import watchUpdateTopic from "./watcher/watchUpdateTopic";
import watchDeleteTopic from "./watcher/watchDeleteTopic";
import watchDeleteTopicSummary from "./watcher/watchDeleteTopicSummary";
import watchSkills from "./watcher/watchSkills";
import watchUpdateSkill from "./watcher/watchUpdateSkill";
import watchDeleteSkill from "./watcher/watchDeleteSkill";
import watchDeleteSkillSummary from "./watcher/watchDeleteSkillSummary";
import watchSSCases from "./watcher/watchSSCases";
import watchSSMaterials from "./watcher/watchSSMaterials";
import watchAwardNames from "./watcher/watchAwardNames";
import watchAwardTypes from "./watcher/watchAwardTypes";
import watchTopicsFinder from "./watcher/watchTopicsFinder";
import watchTopicsSkillSearch from "./watcher/watchTopicsSkillSearch";
import watchSkillsToolsData from "./watcher/watchSkillsToolsData";
import watchSkillTopicsFinder from "./watcher/watchSkillTopicsFinder";
import watchProfileUpdateHistory from "./watcher/watchProfileUpdateHistory";
import watchUserStaffId from "./watcher/watchUserStaffId";
import watchExpertiseList from "./watcher/watchExpertiseList";
import watchChildTopicsFinder from "./watcher/watchChildTopicsFinder";
import watchUpdateAward from "./watcher/watchUpdateAward";
import watchDeleteAward from "./watcher/watchDeleteAward";
import watchFetchCaseResponsibility from "./watcher/watchFetchCaseResponsibility";
import watchUpdateCertificate from "./watcher/watchUpdateCertificate";
import watchDeleteCertificate from "./watcher/watchDeleteCertificate";
import watchDeleteProfileFile from "./watcher/watchDeleteProfileFile";
import watchUpdateProposal from "./watcher/watchUpdateProposal";
import watchProposalFile from "./watcher/watchProposalFile";
import watchUpdateResume from "./watcher/watchUpdateResume";
import watchResumeFile from "./watcher/watchResumeFile";
import watchUpdateProfile from "./watcher/watchUpdateProfile";
import watchAccessRoles from "./watcher/watchAccessRoles";
import watchSaveAccessRoles from "./watcher/watchSaveAccessRoles";
import watchUpdateExpertise from "./watcher/watchUpdateExpertise";
import watchUpdateAboutKT from "./watcher/watchUpdateAboutKT";
import watchMemberList from "./watcher/watchMemberList";
import watchMemberListImage from "./watcher/watchMemberListImage";
import watchPeople from "./watcher/watchPeople";
import watchPeopleImage from "./watcher/watchPeopleImage";
import watchImpersonateImage from "./watcher/watchImpersonateImage";
import watchSavePronunciation from "./watcher/watchSavePronunciation";
import watchDeletePronunciation from "./watcher/watchDeletePronunciation";
import watchOrgMemberImage from "./watcher/watchOrgMemberImage";
import watchSSInternalMaterials from "./watcher/watchSSInternalMaterials";
import watchSSRecentCases from "./watcher/watchSSRecentCases";
import watchSSRecentMaterials from "./watcher/watchSSRecentMaterials";
import watchSSRecentInternalMaterials from "./watcher/watchSSRecentInternalMaterials";
import watchPeopleData from "./watcher/watchPeopleData";
import watchFetchPronunciation from "./watcher/watchFetchPronunciation";
import watchUploadResumeStart from "./watcher/watchUploadResumeStart";
import watchUploadResumeToS3 from "./watcher/watchUploadResumeToS3";
import watchUploadProposalBioToS3 from "./watcher/watchUploadProposalBioToS3";
import watchUploadProposalBioStart from "./watcher/watchUploadProposalBioStart";
import watchUploadResumeToProfile from "./watcher/watchUploadResumeToProfile";
import watchUploadProposalBioToProfile from "./watcher/watchUploadProposalBioToProfile";
import watchFetchResume from "./watcher/watchFetchResume";
import watchFetchProposalBios from "./watcher/watchFetchProposalBios";
import watchDownload from "./watcher/watchDownload";
import watchProficiency from "./watcher/watchProficiency";
import watchDesignatedBy from "./watcher/watchDesignatedBy";
import watchSaveProfileViews from "./watcher/watchSaveProfileViews";
import watchFetchAbout from "./watcher/watchFetchAbout";
import watchBussinessAndSubjects from "./watcher/watchBussinessAndSubjects";
import watchFetchEntitlements from "./watcher/watchFetchEntitlements";
import watchFetchAboutMeAllTerms from "./watcher/watchFetchAboutMeAllTerms";
import watchFetchAboutSponsersDetails from "./watcher/watchFetchAboutSponsersDetails";
import watchUpdateAboutECT from "./watcher/watchUpdateAboutECT";
import watchUpdateAboutSA from "./watcher/watchUpdateAboutSA";
import watchFetchCCO from "./watcher/watchFetchCCO";
import watchFetchAboutTopicDetails from "./watcher/watchFetchAboutTopicDetails";
import watchUpdateProposalBio from "./watcher/watchUpdateProposalBio";
import watchUpdateCaseTopics from "./watcher/watchUpdateCaseTopics";
import watchUpdateCaseSkills from "./watcher/watchUpdateCaseSkills";
import watchDeleteCaseTopicsAnsSkills from "./watcher/watchDeleteCaseTopicsAnsSkills";
import watchPracticeAreaOptions from "./watcher/watchPracticeAreaOptions";
import watchUserData from "./watcher/watchUserData";
import watchFetchLinkedInRedirectUrl from "./watcher/watchFetchLinkedInRedirectUrl";
import watchFetchLinkedInAuthStatus from "./watcher/watchFetchLinkedInAuthStatus";
import watchInitiateLinkedInConnect from "./watcher/watchInitiateLinkedInConnect";
import watchFetchLinkedInData from "./watcher/watchFetchLinkedInData";
import watchUpdateLinkedInDeclineStatus from "./watcher/watchUpdateLinkedInDeclineStatus";
import watchRemoveLinkedInAccess from "./watcher/watchRemoveLinkedInAccess";
import watchProfileCompletion from "./watcher/watchProfileCompletion";
import watchProfileCompletionVerbiage from "./watcher/watchProfileCompletionVerbiage";
import watchFetchProfileViews from "./watcher/watchFetchProfileViews";
import watchSkipForNow from "./watcher/watchSkipForNow";
import watchUpdateProfileCompletion from "./watcher/watchUpdateProfileCompletion";
import watchUpdateLinkedInOptinOptions from "./watcher/watchUpdateLinkedInOptinOptions";
import watchFetchUserLinkedInStatus from "./watcher/watchFetchUserLinkedInStatus";
import watchFetchCVBioMaterialsViews from "./watcher/watchFetchCVBioMaterialsViews";
import watchLanguages from "./watcher/watchLanguages";
import watchCheckExternalCourseId from "./watcher/watchCheckExternalCourseId";

export default function* root() {
  yield all([
    fork(watchUser),
    fork(watchProfile),
    fork(watchProfileImage),
    fork(watchProfileImageHighRes),
    fork(watchExperience),
    fork(watchEducation),
    fork(watchAchievements),
    fork(watchCases),
    fork(watchUpdateCase),
    fork(watchMaterials),
    fork(watchTopics),
    fork(watchUpdateTopic),
    fork(watchDeleteTopic),
    fork(watchDeleteTopicSummary),
    fork(watchSkills),
    fork(watchUpdateSkill),
    fork(watchDeleteSkill),
    fork(watchDeleteSkillSummary),
    fork(watchSSCases),
    fork(watchSSMaterials),
    fork(watchFetchCVBioMaterialsViews),
    fork(watchAwardNames),
    fork(watchLanguages),
    fork(watchAwardTypes),
    fork(watchTopicsFinder),
    fork(watchTopicsSkillSearch),
    fork(watchSkillTopicsFinder),
    fork(watchProfileUpdateHistory),
    fork(watchUserStaffId),
    fork(watchExpertiseList),
    fork(watchChildTopicsFinder),
    fork(watchUpdateAward),
    fork(watchDeleteAward),
    fork(watchUpdateCertificate),
    fork(watchDeleteCertificate),
    fork(watchDeleteProfileFile),
    fork(watchUpdateProposal),
    fork(watchProposalFile),
    fork(watchUpdateResume),
    fork(watchResumeFile),
    fork(watchUpdateProfile),
    fork(watchAccessRoles),
    fork(watchSaveAccessRoles),
    fork(watchUpdateExpertise),
    fork(watchUpdateAboutKT),
    fork(watchMemberList),
    fork(watchMemberListImage),
    fork(watchPeople),
    fork(watchPeopleImage),
    fork(watchImpersonateImage),
    fork(watchSavePronunciation),
    fork(watchDeletePronunciation),
    fork(watchPeopleData),
    fork(watchUserData),
    fork(watchOrgMemberImage),
    fork(watchSSInternalMaterials),
    fork(watchUpdateLinkedInDeclineStatus),
    fork(watchSSRecentCases),
    fork(watchSSRecentMaterials),
    fork(watchSSRecentInternalMaterials),
    fork(watchFetchPronunciation),
    fork(watchUploadResumeStart),
    fork(watchUploadProposalBioStart),
    fork(watchUploadResumeToS3),
    fork(watchUploadProposalBioToS3),
    fork(watchUploadResumeToProfile),
    fork(watchUploadProposalBioToProfile),
    fork(watchFetchResume),
    fork(watchFetchProposalBios),
    fork(watchDownload),
    fork(watchSkillsToolsData),
    fork(watchProficiency),
    fork(watchDesignatedBy),
    fork(watchSaveProfileViews),
    fork(watchFetchProfileViews),
    fork(watchUpdateCaseResponsibilities),
    fork(watchFetchCaseResponsibility),
    fork(watchFetchAbout),
    fork(watchBussinessAndSubjects),
    fork(watchFetchEntitlements),
    fork(watchFetchAboutMeAllTerms),
    fork(watchFetchAboutSponsersDetails),
    fork(watchUpdateAboutECT),
    fork(watchUpdateAboutSA),
    fork(watchFetchCCO),
    fork(watchFetchAboutTopicDetails),
    fork(watchUpdateProposalBio),
    fork(watchUpdateCaseTopics),
    fork(watchUpdateCaseSkills),
    fork(watchDeleteCaseTopicsAnsSkills),
    fork(watchPracticeAreaOptions),
    fork(watchFetchLinkedInRedirectUrl),
    fork(watchFetchLinkedInAuthStatus),
    fork(watchInitiateLinkedInConnect),
    fork(watchFetchLinkedInData),
    fork(watchRemoveLinkedInAccess),
    fork(watchProfileCompletion),
    fork(watchProfileCompletionVerbiage),
    fork(watchUpdateProfileCompletion),
    fork(watchSkipForNow),
    fork(watchUpdateLinkedInOptinOptions),
    fork(watchFetchUserLinkedInStatus),
    fork(watchCheckExternalCourseId)
  ]);
}
