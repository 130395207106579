const user = {
  FETCH_USER: "FETCH_USER",
  FETCH_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_FAILURE: "FETCH_USER_FAILURE",
  UPDATE_ACCESS_ROLE_ID: "UPDATE_ACCESS_ROLE_ID"
};

const profile = {
  FETCH_PROFILE: "FETCH_USER_PROFILE",
  FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_FAILURE: "FETCH_PROFILE_FAILURE",
  UPDATE_PROFILE_PRONUNCIATION: "UPDATE_PROFILE_PRONUNCIATION"
};

const experience = {
  FETCH_EXPERIENCE: "FETCH_EXPERIENCE",
  FETCH_EXPERIENCE_SUCCESS: "FETCH_EXPERIENCE_SUCCESS",
  FETCH_EXPERIENCE_FAILURE: "FETCH_EXPERIENCE_FAILURE"
};

const education = {
  FETCH_EDUCATION: "FETCH_EDUCATION",
  FETCH_EDUCATION_SUCCESS: "FETCH_EDUCATION_SUCCESS",
  FETCH_EDUCATION_FAILURE: "FETCH_EDUCATION_FAILURE"
};

const cases = {
  FETCH_CASES: "FETCH_CASES",
  FETCH_CASES_SUCCESS: "FETCH_CASES_SUCCESS",
  FETCH_CASES_FAILURE: "FETCH_CASES_FAILURE",
  UPDATE_CASE: "UPDATE_CASE",
  UPDATE_CASE_SUCCESS: "UPDATE_CASE_SUCCESS",
  UPDATE_CASE_FAILURE: "UPDATE_CASE_FAILURE",
  SET_ALL_CASES: "SET_ALL_CASES",
  UPDATE_RESPONSIBILITY: "UPDATE_RESPONSIBILITY",
  UPDATE_RESPONSIBILITY_SUCCESS: "UPDATE_RESPONSIBILITY_SUCCESS",
  UPDATE_RESPONSIBILITY_FAILURE: "UPDATE_RESPONSIBILITY_FAILURE",
  SET_RESPONSIBILITY_SUCCESS: "SET_RESPONSIBILITY_SUCCESS",
  SET_RESPONSIBILITY_FAILURE: "SET_RESPONSIBILITY_FAILURE",
  GET_RESPONSIBILITIES: "GET_RESPONSIBILITIES",
  UPDATE_CASE_TOPICS: "UPDATE_CASE_TOPICS",
  UPDATE_CASE_TOPICS_SUCCESS: "UPDATE_CASE_TOPICS_SUCCESS",
  UPDATE_CASE_TOPICS_ERROR: "UPDATE_CASE_TOPICS_ERROR",
  UPDATE_CASE_SKILLS: "UPDATE_CASE_SKILLS",
  UPDATE_CASE_SKILLS_SUCCESS: "UPDATE_CASE_SKILLS_SUCCESS",
  UPDATE_CASE_SKILLS_ERROR: "UPDATE_CASE_SKILLS_ERROR",
  DELETE_CASE_TOPICS_SKILLS: "DELETE_CASE_TOPICS_SKILLS",
  DELETE_CASE_TOPICS_SKILLS_SUCCESS: "DELETE_CASE_TOPICS_SKILLS_SUCCESS",
  DELETE_CASE_TOPICS_SKILLS_ERROR: "DELETE_CASE_TOPICS_SKILLS_ERROR",
  RESET_CASE_TOPICS_SKILL_STATUS: "RESET_CASE_TOPICS_SKILL_STATUS",
  FETCH_CASES_TOPICS_SKILLS_SUCCESS: "FETCH_CASES_TOPICS_SKILLS_SUCCESS",
  RESET_CASE_TOPICS: "RESET_CASE_TOPICS",
  RESET_CASE_SKILLS: "RESET_CASE_SKILLS",
  STATUS: {
    SAVE_SUCCESS: "SAVE_SUCCESS",
    SAVING: "SAVING",
    SAVE_FAILURE: "SAVE_FAILURE",
    DELETING: "DELETING",
    DELETE_SUCCESS: "DELETE_SUCCESS",
    DELETE_FAILURE: "DELETE_FAILURE"
  }
};

const materials = {
  FETCH_MATERIALS: "FETCH_MATERIALS",
  FETCH_MATERIALS_SUCCESS: "FETCH_MATERIALS_SUCCESS",
  FETCH_MATERIALS_FAILURE: "FETCH_MATERIALS_FAILURE"
};

const achievement = {
  FETCH_ACHIEVEMENT: "FETCH_ACHIEVEMENT",
  FETCH_ACHIEVEMENT_SUCCESS: "FETCH_ACHIEVEMENT_SUCCESS",
  FETCH_ACHIEVEMENT_FAILURE: "FETCH_ACHIEVEMENT_FAILURE",
  SAVE_ACHIEVEMENT: "SAVE_ACHIEVEMENT",
  SAVE_ACHIEVEMENT_SUCCESS: "SAVE_ACHIEVEMENT_SUCCESS",
  SAVE_ACHIEVEMENT_FAILURE: "SAVE_ACHIEVEMENT_FAILURE"
};

const topics = {
  FETCH_TOPIC: "FETCH_TOPIC",
  FETCH_TOPIC_SUCCESS: "FETCH_TOPIC_SUCCESS",
  FETCH_TOPIC_FAILURE: "FETCH_TOPIC_FAILURE",
  UPDATE_TOPIC: "UPDATE_TOPIC",
  UPDATE_TOPIC_SUCCESS: "UPDATE_TOPIC_SUCCESS",
  UPDATE_TOPIC_FAILURE: "UPDATE_TOPIC_FAILURE",
  DELETE_TOPIC: "DELETE_TOPIC",
  DELETE_TOPIC_SUCCESS: "DELETE_TOPIC_SUCCESS",
  DELETE_TOPIC_FAILURE: "DELETE_TOPIC_FAILURE",
  DELETE_TOPICSUMMARY: "DELETE_TOPICSUMMARY",
  DELETE_TOPICSUMMARY_SUCCESS: "DELETE_TOPICSUMMARY_SUCCESS",
  DELETE_TOPICSUMMARY_FAILURE: "DELETE_TOPICSUMMARY_FAILURE",
  GET_BUSINESS_SUBJECTS_DATA: "GET_BUSINESS_SUBJECTS_DATA",
  GET_BUSINESS_SUBJECTS_DATA_SUCCESS: "GET_BUSINESS_SUBJECTS_DATA_SUCCESS",
  GET_BUSINESS_SUBJECTS_DATA_FAILURE: "GET_BUSINESS_SUBJECTS_DATA_FAILURE"
};

const skills = {
  FETCH_SKILL: "FETCH_SKILL",
  FETCH_SKILL_SUCCESS: "FETCH_SKILL_SUCCESS",
  FETCH_SKILL_FAILURE: "FETCH_SKILL_FAILURE",
  UPDATE_SKILL: "UPDATE_SKILL",
  UPDATE_SKILL_SUCCESS: "UPDATE_SKILL_SUCCESS",
  UPDATE_SKILL_FAILURE: "UPDATE_SKILL_FAILURE",
  DELETE_SKILL: "DELETE_SKILL",
  DELETE_SKILL_SUCCESS: "DELETE_SKILL_SUCCESS",
  DELETE_SKILL_FAILURE: "DELETE_SKILL_FAILURE",
  DELETE_SKILLSUMMARY: "DELETE_SKILLSUMMARY",
  DELETE_SKILLSUMMARY_SUCCESS: "DELETE_SKILLSUMMARY_SUCCESS",
  DELETE_SKILLSUMMARY_FAILURE: "DELETE_SKILLSUMMARY_FAILURE",
  GET_SKILL_TOOLS_DATA: "GET_SKILL_TOOLS_DATA",
  GET_SKILL_TOOLS_DATA_SUCCESS: "GET_SKILL_TOOLS_DATA_SUCCESS",
  GET_SKILL_TOOLS_DATA_FAILURE: "GET_SKILL_TOOLS_DATA_FAILURE"
};

const ssCases = {
  FETCH_SSCASE: "FETCH_SSCASE",
  FETCH_SSCASE_SUCCESS: "FETCH_SSCASE_SUCCESS",
  FETCH_SSCASE_FAILURE: "FETCH_SSCASE_FAILURE"
};

const ssRecentCases = {
  FETCH_RECENT_SSCASE: "FETCH_RECENT_SSCASE",
  FETCH_RECENT_SSCASE_SUCCESS: "FETCH_RECENT_SSCASE_SUCCESS",
  FETCH_RECENT_SSCASE_FAILURE: "FETCH_RECENT_SSCASE_FAILURE"
};

const ssRecentMaterials = {
  FETCH_RECENT_SSMATERIALS: "FETCH_RECENT_SSMATERIALS",
  FETCH_RECENT_SSMATERIALS_SUCCESS: "FETCH_RECENT_SSMATERIALS_SUCCESS",
  FETCH_RECENT_SSMATERIALS_FAILURE: "FETCH_RECENT_SSMATERIALS_FAILURE"
};

const ssRecentInternalMaterials = {
  FETCH_RECENT_SS_INTERNAL_MATERIALS: "FETCH_RECENT_SS_INTERNAL_MATERIALS",
  FETCH_RECENT_SS_INTERNAL_MATERIALS_SUCCESS: "FETCH_RECENT_SS_INTERNAL_MATERIALS_SUCCESS",
  FETCH_RECENT_SS_INTERNAL_MATERIALS_FAILURE: "FETCH_RECENT_SS_INTERNAL_MATERIALS_FAILURE"
};

const userProfileImage = {
  FETCH_USER_IMAGE: "FETCH_USER_IMAGE",
  FETCH_USER_IMAGE_SUCCESS: "FETCH_USER_IMAGE_SUCCESS",
  FETCH_USER_IMAGE_FAILURE: "FETCH_USER_IMAGE_FAILURE"
};

const userProfileImageHighRes = {
  FETCH_USER_IMAGE_HIGHRES: "FETCH_USER_IMAGE_HIGHRES",
  FETCH_USER_IMAGE_HIGHRES_SUCCESS: "FETCH_USER_IMAGE_HIGHRES_SUCCESS",
  FETCH_USER_IMAGE_HIGHRES_FAILURE: "FETCH_USER_IMAGE_HIGHRES_FAILURE"
};

const ssMaterials = {
  FETCH_SSMATERIALS: "FETCH_SSMATERIALS",
  FETCH_SSMATERIALS_SUCCESS: "FETCH_SSMATERIALS_SUCCESS",
  FETCH_SSMATERIALS_FAILURE: "FETCH_SSMATERIALS_FAILURE"
};

const awardNames = {
  FETCH_AWARD_NAMES: "FETCH_AWARD_NAMES",
  FETCH_AWARD_NAMES_SUCCESS: "FETCH_AWARD_NAMES_SUCCESS",
  FETCH_AWARD_NAMES_FAILURE: "FETCH_AWARD_NAMES_FAILURE"
};

const awardTypes = {
  FETCH_AWARD_TYPES: "FETCH_AWARD_TYPES",
  FETCH_AWARD_TYPES_SUCCESS: "FETCH_AWARD_TYPES_SUCCESS",
  FETCH_AWARD_TYPES_FAILURE: "FETCH_AWARD_TYPES_FAILURE"
};
const topicsFinder = {
  FETCH_TOPICS_FINDER: "FETCH_TOPICS_FINDER",
  FETCH_TOPICS_FINDER_SUCCESS: "FETCH_TOPICS_FINDER_SUCCESS",
  FETCH_TOPICS_FINDER_FAILURE: "FETCH_TOPICS_FINDER_FAILURE"
};

const topicsSkillFinder = {
  FETCH_SKILL_TOPICS_FINDER: "FETCH_SKILL_TOPICS_FINDER",
  FETCH_SKILL_TOPICS_FINDER_SUCCESS: "FETCH_SKILL_TOPICS_FINDER_SUCCESS",
  FETCH_SKILL_TOPICS_FINDER_FAILURE: "FETCH_SKILL_TOPICS_FINDER_FAILURE"
};

const searchTopicsSkills = {
  FETCH_TOPICS_SKILLS_FINDER: "FETCH_TOPICS_SKILLS_FINDER",
  FETCH_TOPICS_SKILLS_FINDER_SUCCESS: "FETCH_TOPICS_SKILLS_FINDER_SUCCESS",
  FETCH_TOPICS_SKILLS_FINDER_FAILURE: "FETCH_TOPICS_SKILLS_FINDER_FAILURE"
};

const saveTopicsSkill = {
  SEND_TOPICS_SKILLS: "SEND_TOPICS_SKILLS",
  SAVE_TOPICS_SKILLS_SUCCESS: "SAVE_TOPICS_SKILLS_SUCCESS",
  SAVE_TOPICS_SKILLS_FAILURE: "SAVE_TOPICS_SKILLS_FAILURE"
};

const deleteTopicsSkill = {
  SEND_DELETE_TOPICS_SKILLS: "SEND_DELETE_TOPICS_SKILLS",
  DELETE_TOPICS_SKILLS_SUCCESS: "DELETE_TOPICS_SKILLS_SUCCESS",
  DELETE_TOPICS_SKILLS_FAILURE: "DELETE_TOPICS_SKILLS_FAILURE"
};

const deleteExpertSummay = {
  SEND_DELETE_EXPERT_SUMMURY: "SEND_DELETE_EXPERT_SUMMURY",
  DELETE_EXPERT_SUMMURY_SUCCESS: "DELETE_EXPERT_SUMMURY_SUCCESS",
  DELETE_EXPERT_SUMMURY_FAILURE: "DELETE_EXPERT_SUMMURY_FAILURE"
};

const profileUpdateHistory = {
  FETCH_PROFILE_UPDATE_HISTORY: "FETCH_PROFILE_UPDATE_HISTORY",
  FETCH_PROFILE_UPDATE_HISTORY_SUCCESS: "FETCH_PROFILE_UPDATE_HISTORY_SUCCESS",
  FETCH_PROFILE_UPDATE_HISTORY_FAILURE: "FETCH_PROFILE_UPDATE_HISTORY_FAILURE"
};

const userStaffId = {
  FETCH_USER_STAFF_ID: "FETCH_USER_STAFF_ID",
  FETCH_USER_STAFF_ID_SUCCESS: "FETCH_USER_STAFF_ID_SUCCESS",
  FETCH_USER_STAFF_ID_FAILURE: "FETCH_USER_STAFF_ID_FAILURE"
};

const expertiseList = {
  FETCH_EXPERTISE_SUMMARY: "FETCH_EXPERTISE_SUMMARY",
  FETCH_EXPERTISE_SUMMARY_SUCCESS: "FETCH_EXPERTISE_SUMMARY_SUCCESS",
  FETCH_EXPERTISE_SUMMARY_FAILURE: "FETCH_EXPERTISE_SUMMARY_FAILURE"
};

const memberList = {
  FETCH_MEMBER_LIST: "FETCH_MEMBER_LIST",
  FETCH_MEMBER_LIST_SUCCESS: "FETCH_MEMBER_LIST_SUCCESS",
  FETCH_MEMBER_LIST_FAILURE: "FETCH_MEMBER_LIST_FAILURE",
  FETCH_MEMBER_LIST_IMAGE: "FETCH_MEMBER_LIST_IMAGE",
  FETCH_MEMBER_LIST_IMAGE_SUCCESS: "FETCH_MEMBER_LIST_IMAGE_SUCCESS",
  FETCH_MEMBER_LIST_IMAGE_FAILURE: "FETCH_MEMBER_LIST_IMAGE_FAILURE"
};

const childTopicsFinder = {
  FETCH_CHILD_TOPICS_FINDER: "FETCH_CHILD_TOPICS_FINDER",
  FETCH_CHILD_TOPICS_FINDER_SUCCESS: "FETCH_CHILD_TOPICS_FINDER_SUCCESS",
  FETCH_CHILD_TOPICS_FINDER_FAILURE: "FETCH_CHILD_TOPICS_FINDER_FAILURE"
};

const award = {
  UPDATE_AWARD: "UPDATE_AWARD",
  UPDATE_AWARD_SUCCESS: "UPDATE_AWARD_SUCCESS",
  UPDATE_AWARD_FAILURE: "UPDATE_AWARD_FAILURE",
  DELETE_AWARD: "DELETE_AWARD",
  DELETE_AWARD_SUCCESS: "DELETE_AWARD_SUCCESS",
  DELETE_AWARD_FAILURE: "DELETE_AWARD_FAILURE"
};

const certificate = {
  UPDATE_CERTIFICATE: "UPDATE_CERTIFICATE",
  UPDATE_CERTIFICATE_SUCCESS: "UPDATE_CERTIFICATE_SUCCESS",
  UPDATE_CERTIFICATE_FAILURE: "UPDATE_CERTIFICATE_FAILURE",
  DELETE_CERTIFICATE: "DELETE_CERTIFICATE",
  DELETE_CERTIFICATE_SUCCESS: "DELETE_CERTIFICATE_SUCCESS",
  DELETE_CERTIFICATE_FAILURE: "DELETE_CERTIFICATE_FAILURE"
};

const editProfile = {
  DELETE_FILE: "DELETE_FILE",
  DELETE_FILE_SUCCESS: "DELETE_FILE_SUCCESS",
  DELETE_FILE_FAILURE: "DELETE_FILE_FAILURE",
  UPDATE_PROPOSAL: "UPDATE_PROPOSAL",
  UPDATE_PROPOSAL_SUCCESS: "UPDATE_PROPOSAL_SUCCESS",
  UPDATE_PROPOSAL_FAILURE: "UPDATE_PROPOSAL_FAILURE",
  PROPOSAL_FILE: "PROPOSAL_FILE",
  PROPOSAL_FILE_SUCCESS: "PROPOSAL_FILE_SUCCESS",
  PROPOSAL_FILE_FAILURE: "PROPOSAL_FILE_FAILURE",
  UPDATE_RESUME: "UPDATE_RESUME",
  UPDATE_RESUME_SUCCESS: "UPDATE_RESUME_SUCCESS",
  UPDATE_RESUME_FAILURE: "UPDATE_RESUME_FAILURE",
  RESUME_FILE: "RESUME_FILE",
  RESUME_FILE_SUCCESS: "RESUME_FILE_SUCCESS",
  RESUME_FILE_FAILURE: "RESUME_FILE_FAILURE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE"
};

const userAccessRoles = {
  OPEN_ACCESS_ROLE_MODAL: "OPEN_ACCESS_ROLE_MODAL",
  FETCH_USER_ACCESS_ROLES: "FETCH_USER_ACCESS_ROLES",
  FETCH_USER_ACCESS_ROLES_SUCCESS: "FETCH_USER_ACCESS_ROLES_SUCCESS",
  FETCH_USER_ACCESS_ROLES_FAILURE: "FETCH_USER_ACCESS_ROLES_FAILURE",
  SAVE_USER_ACCESS_ROLES: "SAVE_USER_ACCESS_ROLES",
  SAVE_USER_ACCESS_ROLES_SUCCESS: "SAVE_USER_ACCESS_ROLES_SUCCESS",
  SAVE_USER_ACCESS_ROLES_FAILURE: "SAVE_USER_ACCESS_ROLES_FAILURE"
};

const manageExpertise = {
  UPDATE_EXPERTISE: "UPDATE_EXPERTISE",
  UPDATE_EXPERTISE_SUCCESS: "UPDATE_EXPERTISE_SUCCESS",
  UPDATE_EXPERTISE_FAILURE: "UPDATE_EXPERTISE_FAILURE"
};

const about = {
  UPDATE_ABOUT_KT: "UPDATE_ABOUT_KT",
  UPDATE_ABOUT_KT_SUCCESS: "UPDATE_ABOUT_KT_SUCCESS",
  UPDATE_ABOUT_KT_FAILURE: "UPDATE_ABOUT_KT_FAILURE",
  UPDATE_ABOUT_ECT: "UPDATE_ABOUT_ECT",
  UPDATE_ABOUT_ECT_SUCCESS: "UPDATE_ABOUT_ECT_SUCCESS",
  UPDATE_ABOUT_ECT_FAILURE: "UPDATE_ABOUT_ECT_FAILURE",
  UPDATE_ABOUT_SA: "UPDATE_ABOUT_SA",
  UPDATE_ABOUT_SA_SUCCESS: "UPDATE_ABOUT_SA_SUCCESS",
  UPDATE_ABOUT_SA_FAILURE: "UPDATE_ABOUT_SA_FAILURE",
  FETCH_ABOUT: "FETCH_ABOUT",
  FETCH_ABOUT_SUCCESS: "FETCH_ABOUT_SUCCESS",
  FETCH_ABOUT_FAILURE: "FETCH_ABOUT_FAILURE",
  FETCH_ABOUT_ME_ALL_TERMS: "FETCH_ABOUT_ME_ALL_TERMS",
  FETCH_ABOUT_ME_ALL_TERMS_SUCCESS: "FETCH_ABOUT_ME_ALL_TERMS_SUCCESS",
  FETCH_ABOUT_ME_ALL_TERMS_FAILURE: "FETCH_ABOUT_ME_ALL_TERMS_FAILURE",
  FETCH_ABOUT_SPONSER_DETAILS: "FETCH_ABOUT_SPONSER_DETAILS",
  FETCH_ABOUT_SPONSER_DETAILS_SUCCESS: "FETCH_ABOUT_SPONSER_DETAILS_SUCCESS",
  FETCH_ABOUT_SPONSER_DETAIL_FAILURE: "FETCH_ABOUT_SPONSER_DETAIL_FAILURE",
  FETCH_ABOUT_TOPIC_DETAILS: "FETCH_ABOUT_TOPIC_DETAILS",
  FETCH_ABOUT_TOPIC_DETAILS_SUCCESS: "FETCH_ABOUT_TOPIC_DETAILS_SUCCESS",
  RESET_ABOUT_TOPIC_DETAILS: "RESET_ABOUT_TOPIC_DETAILS",
  FETCH_PA_OPTIONS: "FETCH_PA_OPTIONS",
  FETCH_PA_OPTIONS_SUCCESS: "FETCH_PA_OPTIONS_SUCCESS",
  FETCH_PA_OPTIONS_FAILURE: "FETCH_PA_OPTIONS_FAILURE",
  SET_ABOUT_ADDITIONAL_DATA: "SET_ABOUT_ADDITIONAL_DATA"
};

const people = {
  FETCH_PEOPLE: "FETCH_PEOPLE",
  FETCH_PEOPLE_SUCCESS: "FETCH_PEOPLE_SUCCESS",
  FETCH_PEOPLE_FAILURE: "FETCH_PEOPLE_FAILURE",
  FETCH_PEOPLE_IMAGE: "FETCH_PEOPLE_IMAGE",
  FETCH_PEOPLE_IMAGE_SUCCESS: "FETCH_PEOPLE_IMAGE_SUCCESS",
  FETCH_PEOPLE_IMAGE_FAILURE: "FETCH_PEOPLE_IMAGE_FAILURE"
};

const impersonate = {
  FETCH_IMAGE: "FETCH_IMAGE",
  FETCH_IMAGE_SUCCESS: "FETCH_IMAGE_SUCCESS",
  FETCH_IMAGE_FAILURE: "FETCH_IMAGE_FAILURE"
};

const pronunciation = {
  SAVE_PRONUNCIATION: "SAVE_PRONUNCIATION",
  SAVE_PRONUNCIATION_SUCCESS: "SAVE_PRONUNCIATION_SUCCESS",
  SAVE_PRONUNCIATION_FAILURE: "SAVE_PRONUNCIATION_FAILURE",
  FETCH_PRONUNCIATION: "FETCH_PRONUNCIATION",
  FETCH_PRONUNCIATION_SUCCESS: "FETCH_PRONUNCIATION_SUCCESS",
  FETCH_PRONUNCIATION_FAILURE: "FETCH_PRONUNCIATION_FAILURE",
  DELETE_PRONUNCIATION: "DELETE_PRONUNCIATION",
  DELETE_PRONUNCIATION_SUCCESS: "DELETE_PRONUNCIATION_SUCCESS",
  DELETE_PRONUNCIATION_FAILURE: "DELETE_PRONUNCIATION_FAILURE",
  RESET_PRONUNCIATION_STATE: "RESET_PRONUNCIATION_STATE",
  SET_PRONUNCIATION_ID: "SET_PRONUNCIATION_ID"
};

const organization = {
  FETCH_ORG: "FETCH_ORG",
  FETCH_ORG_SUCCESS: "FETCH_ORG_SUCCESS",
  FETCH_ORG_FAILURE: "FETCH_ORG_FAILURE",
  FETCH_ORG_MEMBER_IMAGE: "FETCH_ORG_MEMBER_IMAGE",
  FETCH_ORG_MEMBER_IMAGE_SUCCESS: "FETCH_ORG_MEMBER_IMAGE_SUCCESS",
  FETCH_ORG_MEMBER_IMAGE_FAILURE: "FETCH_ORG_MEMBER_IMAGE_FAILURE"
};

const peopleData = {
  FETCH_PEOPLE_DATA: "FETCH_PEOPLE_DATA",
  FETCH_PEOPLE_DATA_SUCCESS: "FETCH_PEOPLE_DATA_SUCCESS",
  FETCH_PEOPLE_DATA_FAILURE: "FETCH_PEOPLE_DATA_FAILURE"
};

const userData = {
  FETCH_USER_DATA: "FETCH_USER_DATA",
  FETCH_USER_DATA_SUCCESS: "FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_FAILURE: "FETCH_USER_DATA_FAILURE"
};

const ssInternalMaterials = {
  FETCH_SSINTERNAL_MATERIALS: "FETCH_SSINTERNAL_MATERIALS",
  FETCH_SSINTERNAL_MATERIALS_SUCCESS: "FETCH_SSINTERNAL_MATERIALS_SUCCESS",
  FETCH_SSINTERNAL_MATERIALS_FAILURE: "FETCH_SSINTERNAL_MATERIALS_FAILURE"
};

const upload = {
  UPLOAD_RESUME_START: "UPLOAD_RESUME_START",
  UPLOAD_PROPOSAL_BIO_START: "UPLOAD_PROPOSAL_BIO_START",
  UPLOAD_RESUME_SUCCESS: "UPLOAD_RESUME_SUCCESS",
  UPLOAD_BIO_SUCCESS: "UPLOAD_RESUME_SUCCESS",
  UPLOAD_RESUME_ERROR: "UPLOAD_RESUME_ERROR",
  UPLOAD_BIO_ERROR: "UPLOAD_RESUME_ERROR",
  UPLOAD_RESUME_TO_S3: "UPLOAD_RESUME_TO_S3",
  UPLOAD_PROPOSAL_BIO_T0_S3: "UPLOAD_PROPOSAL_BIO_T0_S3",
  UPLOAD_RESUME_TO_PROFILE: "UPLOAD_RESUME_TO_PROFILE",
  UPLOAD_PROPOSAL_BIO_T0_PROFILE: "UPLOAD_PROPOSAL_BIO_T0_PROFILE",
  UPDATE_PROPOSAL_BIO: "UPDATE_PROPOSAL_BIO",
  UPDATE_PROPOSAL_BIO_SUCCESS: "UPDATE_PROPOSAL_BIO_SUCCESS",
  UPDATE_PROPOSAL_BIO_FAILURE: "UPDATE_PROPOSAL_BIO_FAILURE",
  UPDATE_RESUME: "UPDATE_RESUME",
  UPDATE_RESUME_SUCCESS: "UPDATE_RESUME_SUCCESS",
  UPDATE_RESUME_FAILURE: "UPDATE_RESUME_FAILURE"
};

const resume = {
  FETCH_RESUME: "FETCH_RESUME_PROFILE",
  FETCH_RESUME_SUCCESS: "FETCH_RESUME_SUCCESS",
  FETCH_RESUME_FAILURE: "FETCH_RESUME_FAILURE"
};

const proposalBios = {
  FETCH_PROPOSAL_BIOS: "FETCH_PROPOSAL_BIOS_PROFILE",
  FETCH_PROPOSAL_BIOS_SUCCESS: "FETCH_PROPOSAL_BIOS_SUCCESS",
  FETCH_PROPOSAL_BIOS_FAILURE: "FETCH_PROPOSAL_BIOS_FAILURE"
};

const download = {
  DOWNLOAD_START: "DOWNLOAD_START",
  DOWNLOAD_SUCCESS: "DOWNLOAD_SUCCESS",
  DOWNLOAD_FAILURE: "DOWNLOAD_FAILURE"
};

const proficiency = {
  FETCH_PROFICIENCY: "FETCH_PROFICIENCY",
  FETCH_PROFICIENCY_SUCCESS: "FETCH_PROFICIENCY_SUCCESS",
  FETCH_PROFICIENCY_FAILURE: "FETCH_PROFICIENCY_FAILURE"
};

const designatedBy = {
  FETCH_DESIGNATEDBY: "FETCH_DESIGNATEDBY",
  FETCH_DESIGNATEDBY_SUCCESS: "FETCH_DESIGNATEDBY_SUCCESS",
  FETCH_DESIGNATEDBY_FAILURE: "FETCH_DESIGNATEDBY_FAILURE"
};

const profileViews = {
  SAVE_PROFILE_VIEWS: "SAVE_PROFILE_VIEWS",
  SAVE_PROFILE_VIEWS_SUCCESS: "SAVE_PROFILE_VIEWS_SUCCESS",
  SAVE_PROFILE_VIEWS_FAILURE: "SAVE_PROFILE_VIEWS_FAILURE",
  FETCH_PROFILE_VIEW_DETAILS: "FETCH_PROFILE_VIEW_DETAILS",
  FETCH_PROFILE_VIEW_DETAILS_SUCCESS: "FETCH_PROFILE_VIEW_DETAILS_SUCCESS",
  FETCH_PROFILE_VIEW_DETAILS_FAILURE: "FETCH_PROFILE_VIEW_DETAILS_FAILURE",
  FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS: "FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS",
  FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_SUCCESS: "FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_SUCCESS",
  FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_FAILURE: "FETCH_CV_BIO_MATERIALS_VIEWS_DETAILS_FAILURE"
};

const entitlements = {
  FETCH_ENTITLEMENTS: "FETCH_ENTITLEMENTS",
  FETCH_ENTITLEMENTS_SUCCESS: "FETCH_ENTITLEMENTS_SUCCESS",
  FETCH_ENTITLEMENTS_FAILURE: "FETCH_ENTITLEMENTS_FAILURE"
};

const cco = {
  FETCH_CCO: "FETCH_CCO",
  FETCH_CCO_SUCCESS: "FETCH_CCO_SUCCESS",
  FETCH_CCO_FAILURE: "FETCH_CCO_FAILURE"
};

const linkedIn = {
  FETCH_LINKEDIN_REDIRECT_URL: "FETCH_LINKEDIN_REDIRECT_URL",
  FETCH_LINKEDIN_REDIRECT_URL_SUCCESS: "FETCH_LINKEDIN_REDIRECT_URL_SUCCESS",
  FETCH_LINKEDIN_REDIRECT_URL_FAILURE: "FETCH_LINKEDIN_REDIRECT_URL_FAILURE",
  FETCH_LINKEDIN_AUTH_STATUS: "FETCH_LINKEDIN_AUTH_STATUS",
  FETCH_LINKEDIN_AUTH_STATUS_SUCCESS: "FETCH_LINKEDIN_AUTH_STATUS_SUCCESS",
  FETCH_LINKEDIN_AUTH_STATUS_FAILURE: "FETCH_LINKEDIN_AUTH_STATUS_FAILURE",
  INITIATE_LINKEDIN_CONNECT: "INITIATE_LINKEDIN_CONNECT",
  INITIATE_LINKEDIN_CONNECT_SUCCESS: "INITIATE_LINKEDIN_CONNECT_SUCCESS",
  INITIATE_LINKEDIN_CONNECT_FAILURE: "INITIATE_LINKEDIN_CONNECT_FAILURE",
  FETCH_LINKEDIN_DATA: "FETCH_LINKEDIN_DATA",
  FETCH_LINKEDIN_DATA_SUCCESS: "FETCH_LINKEDIN_DATA_SUCCESS",
  FETCH_LINKEDIN_DATA_FAILURE: "FETCH_LINKEDIN_DATA_FAILURE",
  UPDATE_LINKEDIN_DECLINE_STATUS: "UPDATE_LINKEDIN_DECLINE_STATUS",
  UPDATE_LINKEDIN_DECLINE_STATUS_SUCCESS: "UPDATE_LINKEDIN_DECLINE_STATUS_SUCCESS",
  UPDATE_LINKEDIN_DECLINE_STATUS_FAILURE: "UPDATE_LINKEDIN_DECLINE_STATUS_FAILURE",
  REMOVE_LINKEDIN_ACCESS: "REMOVE_LINKEDIN_ACCESS",
  REMOVE_LINKEDIN_ACCESS_SUCCESS: "REMOVE_LINKEDIN_ACCESS_SUCCESS",
  REMOVE_LINKEDIN_ACCESS_FAILURE: "REMOVE_LINKEDIN_ACCESS_FAILURE",
  UPDATE_LINKEDIN_OPTIN_OPTIONS: "UPDATE_LINKEDIN_OPTIN_OPTIONS",
  UPDATE_LINKEDIN_OPTIN_OPTIONS_SUCCESS: "UPDATE_LINKEDIN_OPTIN_OPTIONS_SUCCESS",
  UPDATE_LINKEDIN_OPTIN_OPTIONS_FAILURE: "UPDATE_LINKEDIN_OPTIN_OPTIONS_FAILURE",
  FETCH_USER_LINKEDIN_STATUS: "FETCH_USER_LINKEDIN_STATUS",
  FETCH_USER_LINKEDIN_STATUS_SUCCESS: "FETCH_USER_LINKEDIN_STATUS_SUCCESS",
  FETCH_USER_LINKEDIN_STATUS_FAILURE: "FETCH_USER_LINKEDIN_STATUS_FAILURE"
};

const profileCompletion = {
  FETCH_PROFILE_COMPLETION: "FETCH_PROFILE_COMPLETION",
  FETCH_PROFILE_COMPLETION_SUCCESS: "FETCH_PROFILE_COMPLETION_SUCCESS",
  FETCH_PROFILE_COMPLETION_FAILURE: "FETCH_PROFILE_COMPLETION_FAILURE",
  FETCH_PROFILE_COMPLETION_VERBIAGE: "FETCH_PROFILE_COMPLETION_VERBIAGE",
  FETCH_PROFILE_COMPLETION_VERBIAGE_SUCCESS: "FETCH_PROFILE_COMPLETION_VERBIAGE_SUCCESS",
  FETCH_PROFILE_COMPLETION_VERBIAGE_FAILURE: "FETCH_PROFILE_COMPLETION_VERBIAGE_FAILURE",
  SET_SKIP_FOR_NOW: "SET_SKIP_FOR_NOW",
  SET_SKIP_FOR_NOW_SUCCESS: "SET_SKIP_FOR_NOW_SUCCESS",
  SET_SKIP_FOR_NOW_FAILURE: "SET_SKIP_FOR_NOW_FAILURE",
  UPDATE_PROFILE_COMPLETE: "UPDATE_PROFILE_COMPLETE",
  UPDATE_PROFILE_COMPLETE_SUCCESS: "UPDATE_PROFILE_COMPLETE_SUCCESS",
  UPDATE_PROFILE_COMPLETE_FAILURE: "UPDATE_PROFILE_COMPLETE_FAILURE"
};
const language = {
  FETCH_LANGUAGE: "FETCH_LANGUAGE",
  FETCH_LANGUAGE_SUCCESS: "FETCH_LANGUAGE_SUCCESS",
  FETCH_LANGUAGE_FAILURE: "FETCH_LANGUAGE_FAILURE"
};

const externalCourseId = {
  CHECK_COURSE_ID: "CHECK_COURSE_ID",
  CHECK_COURSE_ID_SUCCESS: "CHECK_COURSE_ID_SUCCESS",
  CHECK_COURSE_ID_FAILURE: "CHECK_COURSE_ID_FAILURE"
};

export {
  user,
  experience,
  cases,
  materials,
  education,
  achievement,
  profile,
  topics,
  skills,
  ssCases,
  userProfileImage,
  userProfileImageHighRes,
  ssMaterials,
  awardNames,
  awardTypes,
  topicsFinder,
  searchTopicsSkills,
  topicsSkillFinder,
  saveTopicsSkill,
  deleteTopicsSkill,
  deleteExpertSummay,
  profileUpdateHistory,
  userStaffId,
  expertiseList,
  childTopicsFinder,
  award,
  certificate,
  editProfile,
  userAccessRoles,
  memberList,
  manageExpertise,
  about,
  people,
  impersonate,
  pronunciation,
  organization,
  ssInternalMaterials,
  ssRecentCases,
  ssRecentMaterials,
  ssRecentInternalMaterials,
  peopleData,
  userData,
  upload,
  resume,
  proposalBios,
  download,
  proficiency,
  designatedBy,
  profileViews,
  entitlements,
  cco,
  linkedIn,
  profileCompletion,
  language,
  externalCourseId
};
