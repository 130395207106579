import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-semantic-toasts";
import CONFIG from "constants/config";
import LABEL from "constants/label";
import LinkedInMainPopup from "components/LinkedInModal/LinkedInMainPopup";
import LinkedInCancelConfirmation from "components/LinkedInModal/LinkedInCancelConfirmation";
import LinkedInRemoveAccessConfirmation from "components/LinkedInModal/LinkedInRemoveAccessConfirmation";
import ConfirmationModalWithIcon from "components/LinkedInModal/ConfirmationModalWithIcon";

import {
  getLinkedInData,
  getLinkedInRedirectUrl,
  getUserLinkedInStatus,
  updateLinkedInDeclineStatus
} from "redux/actions/linkedIn";
import { useHistory, useLocation } from "react-router";
import { isEmptyOrNull } from "helpers/utils";

const { xApiKey, apiUrl } = CONFIG;

const {
  LINKEDIN: { LINKEDIN_REDIRECT_WARNING }
} = LABEL;

function LinkedInModal({ open, onModalClose, linkedInAlreadyOpted }) {
  const dispatch = useDispatch();
  const [componentToShow, setComponentToShow] = useState(0);
  const [selectedItems, setSelectedItems] = useState("");
  const {
    linkedInRedirectUrl,
    profile,
    linkedInConnectFinalStatus,
    linkedInConnectError,
    connectingToLinkedIn,
    linkedInDeclineStatus
  } = useSelector((state) => state);

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (!connectingToLinkedIn && profile?.hrId) history.push(`${pathname}${location.search}`);
  }, [connectingToLinkedIn, profile?.hrId]);

  useEffect(() => {
    if (linkedInRedirectUrl?.url) window.location.href = linkedInRedirectUrl?.url;
  }, [linkedInRedirectUrl]);

  useEffect(() => {
    if ((!isEmptyOrNull(linkedInConnectFinalStatus) || linkedInConnectError) && !!profile?.hrId) {
      dispatch(
        getLinkedInData({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/data`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      dispatch(
        getUserLinkedInStatus({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/userOptinStatus`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [linkedInConnectFinalStatus, profile?.hrId]);

  // the below useEffect will be updated once we have the design for new Popup.
  useEffect(() => {
    if (linkedInDeclineStatus?.code === 200 || linkedInConnectFinalStatus?.code === 0)
      toast({
        title: "LinkedIn Integration",
        description: "You have decided not to connect with your LinkedIn profile.",
        type: "warning",
        time: "5000"
      });
  }, [linkedInDeclineStatus, linkedInConnectFinalStatus]);

  const onPopupClose = () => {
    setComponentToShow(0);
    onModalClose();
  };

  useEffect(() => {
    if (linkedInDeclineStatus?.code === 200) {
      onPopupClose();
    }
  }, [linkedInDeclineStatus]);
  let Component;

  const onLinkedInConfirmationCancel = () => {
    setComponentToShow(0);
  };

  const onLinkedInDeclineConfirmation = (message) => {
    dispatch(
      updateLinkedInDeclineStatus({
        endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/deny`,
        data: {
          denyComment: message
        },
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  };

  const onAllowCb = (selectedOptions) => {
    setSelectedItems(selectedOptions);
    setComponentToShow(3);
    dispatch(
      getLinkedInRedirectUrl({
        endpoint: `${apiUrl.linkApi}linkedin/login?hrId=${profile?.hrId}&optin=${selectedOptions}`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  };

  if (componentToShow === 0) {
    Component = (
      <LinkedInMainPopup
        open={open}
        onModalClose={onModalClose}
        linkedInAlreadyOpted={linkedInAlreadyOpted}
        setComponentToShow={setComponentToShow}
        onAllowCb={onAllowCb}
      />
    );
  }

  if (componentToShow === 1) {
    Component = (
      <LinkedInCancelConfirmation
        open={open}
        onDeclineConfirm={onLinkedInDeclineConfirmation}
        imgSrc="/icons/exclamation-triangle.svg"
      />
    );
  }

  if (componentToShow === 2) {
    Component = (
      <LinkedInRemoveAccessConfirmation
        open={open}
        onModalClose={onPopupClose}
        setComponentToShow={setComponentToShow}
      />
    );
  }
  if (componentToShow === 3) {
    Component = (
      <ConfirmationModalWithIcon
        isOpen={open}
        modalMessage={LINKEDIN_REDIRECT_WARNING}
        handleCancelClick={onLinkedInConfirmationCancel}
        confirmText={LABEL.COMMON.Proceed}
        cancelText={LABEL.COMMON.Cancel}
        imgSrc="/icons/yellow-exclamation-circle.svg"
      />
    );
  }

  return <>{Component}</>;
}

LinkedInModal.defaultProps = {
  linkedInAlreadyOpted: false
};

LinkedInModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  linkedInAlreadyOpted: PropTypes.bool
};

export default LinkedInModal;
