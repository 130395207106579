import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LABEL from "constants/label";
import PeoplePicker from "components/common/PeoplePicker";
import TopicPicker from "components/common/TopicPickerNew";
import { Modal, Button, Grid, Select, Icon, Segment, Dimmer, Loader } from "semantic-ui-react";
import { Icon as IconC } from "components/common";
import { toastSuccess, toastError, isEmptyOrNull } from "helpers/utils";
import CONFIG from "constants/config";
import { getAbout, updateAboutECT, updateAboutKT, updateAboutSA } from "redux/actions/about";
import TaxonomySelector from "components/TaxonomySelector";
import decoratePAOptions from "components/EditAbout/helpers/utils";
import "./style.scss";
import { fetchProfileCompletionData } from "components/App/handlers/handlers";

const { COMMON, ABOUT } = LABEL;

const {
  ABOUT: {
    EDIT: { SRADVISOR, ECT, KT }
  }
} = LABEL;

/**
 * Edit About
 *
 * @param  {object} profile user profile object
 * @param  {object} permissions user permissions for Edit About popup
 * @param  {bool} open popup open/close state
 * @param  {func} onClose function fires on closing of popup
 * @param  {func} onOpen function fires on opening of popup
 *
 * @return Edit About component
 */

const EditAbout = ({ profile, permissions, open, onClose, onOpen, ktDetails, ectDetails, saDetails }) => {
  const dispatch = useDispatch();
  const { xApiKey, apiUrl } = CONFIG;

  const { ktPracticeArea, ktTopic, ktSubTopic, aboutKtId } = ktDetails || {};
  const { sponsorPA, primaryTopic, sponsor, aboutEctId } = ectDetails || {};
  const { saPracticeArea1, saPracticeArea2, sponsor1, sponsor2, aboutSaId } = saDetails || {};

  const { aboutUpdating, aboutUpdatingError, aboutMeLoading, ccoData, peopleData, practiceAreaOptions } = useSelector(
    (state) => state
  );

  const { canEditEct, canEditSA, canEditKT, canEditCCO } = permissions;

  // Practice Areas
  const [practiceAreasOne, setPracPracticeAreasOne] = useState(saPracticeArea1?.name || "");
  const [practiceAreasOneID, setPracticeAreasOneID] = useState(saPracticeArea1?.id || null);
  const [practiceAreasTwo, setPracPracticeAreasTwo] = useState(saPracticeArea2?.name || "");
  const [practiceAreasTwoID, setPracticeAreasTwoID] = useState(saPracticeArea2?.id || null);

  // Sponsors
  const [sponsorOne, setSponsorOne] = useState(sponsor1?.name || null);
  const [sponsorOneHrID, setSponsorOneHrID] = useState(sponsor1?.id);

  const [sponsorTwo, setSponsorTwo] = useState(sponsor2?.name || null);
  const [sponsorTwoHrID, setSponsorTwoHrID] = useState(sponsor2?.id);

  // ECT
  const [ectSponsorHrId, setEctSponsorHrId] = useState(sponsor?.id || null);
  const [ectSponsor, setEctSponsor] = useState(sponsor?.name || "");
  const [practiceAreaEctID, setPracticeAreaEctID] = useState(sponsorPA?.id || null);
  const [practiceAreaEct, setPracticeAreaEct] = useState(sponsorPA?.name || "");
  const [ectSponsorTopicId, setEctSponsorTopicId] = useState(primaryTopic?.id || null);
  const [ectSponsorTopic, setEctSponsorTopic] = useState(primaryTopic?.name || "");

  const sortedPracticeAreaOptions = [
    { key: 0, value: "", text: ECT.SelectPA },
    ...practiceAreaOptions?.sort((PAOne, PATwo) => {
      if (PAOne?.text < PATwo?.text) return -1;
      if (PAOne?.text > PATwo?.text) return 1;
      return 0;
    })
  ];

  const [practiceAreaOptionsOne, setPracticeAreaOptionsOne] = useState(sortedPracticeAreaOptions);
  const [practiceAreaOptionsTwo, setPracticeAreaOptionsTwo] = useState(sortedPracticeAreaOptions);

  const getEctPracticeAreaOptions = () => {
    const newPAOptions = Array.from(sortedPracticeAreaOptions);
    newPAOptions.splice(0, 1, { key: 0, value: "", text: ECT.SponsorPAPlaceholderText });
    return newPAOptions;
  };

  // Warning popup
  const [isdirty, setIsDirty] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [openWarnPopup, setOpenWarnPopup] = useState(false);

  const [ktPATopicID, setKTPATopicID] = useState(ktPracticeArea?.id || null);
  const [ktPATopicName, setKTPATopicName] = useState(ktPracticeArea?.name || "");
  const [ktPrimaryTopicID, setKTPrimaryTopicID] = useState(ktTopic?.id || null);
  const [ktPrimaryTopicName, setKTPrimaryTopicName] = useState(ktTopic?.name || "");
  const [ktSubTopicID, setKTSubTopicID] = useState(ktSubTopic?.id || null);
  const [ktSubTopicName, setKTSubTopicName] = useState(ktSubTopic?.name || "");

  const checkDirty = () => {
    if (isdirty === true || isFormChanged) {
      setOpenWarnPopup(true);
    } else {
      onClose();
    }
  };

  const isCombiProfile =
    [
      profile.isECT, // is ECT
      profile.isSA, // is Senior Advisor
      profile.isKT, // is Knowledge Team
      !!profile.ccoCompanies?.length // is with CCO/s
    ].filter((profileUserGroup) => !!profileUserGroup).length > 1;

  // Listen to window or tab closing
  useEffect(() => {
    window.onbeforeunload =
      isdirty && isCombiProfile && (() => "Are you sure you want to leave without saving your changes?");
    return () => {
      window.onbeforeunload = null;
    };
  });

  // Submit Form
  const [saveLoading, setSaveLoading] = useState(false);

  const saveAboutKT = () => {
    const updateKTdata = {
      aboutKtId,
      practiceAreaGuid: ktPATopicID,
      primaryTopicGuid: ktPrimaryTopicID,
      subTopicGuid: ktSubTopicID
    };
    setSaveLoading(true);
    try {
      dispatch(
        updateAboutKT({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/aboutkt`,
          data: updateKTdata,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      setSaveLoading(false);
      toastError();
    }
  };

  const saveAboutECT = () => {
    const updateECTdata = {
      aboutEctId,
      sponsorPracticeAreaGuid: practiceAreaEctID,
      primaryTopicGuid: ectSponsorTopicId,
      sponsorHrId: ectSponsorHrId
    };
    setSaveLoading(true);
    try {
      dispatch(
        updateAboutECT({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/aboutect`,
          data: updateECTdata,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      setSaveLoading(false);
      toastError();
    }
  };

  const saveAboutSA = () => {
    const updateSAdata = {
      aboutSaId,
      practiceArea1Guid: practiceAreasOneID,
      practiceArea2Guid: practiceAreasTwoID,
      sponsor1HrId: sponsorOneHrID,
      sponsor2HrId: sponsorTwoHrID
    };
    setSaveLoading(true);
    try {
      dispatch(
        updateAboutSA({
          endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/aboutsa`,
          data: updateSAdata,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    } catch (e) {
      setSaveLoading(false);
      toastError();
    }
  };

  const checkIsSubmitDisable = () => {
    let isSubmitDisable = false;
    if (profile?.isECT) {
      if (!(practiceAreaEctID && ectSponsorTopicId && ectSponsorHrId)) {
        isSubmitDisable = true;
      }
    }
    setIsDirty(!isSubmitDisable);
  };

  useEffect(() => {
    if (isdirty && profile?.isECT && isFormChanged) {
      checkIsSubmitDisable();
    }
  }, [practiceAreaEctID, ectSponsorTopicId, ectSponsorHrId]);

  const handleSubmit = () => {
    if (profile?.isKT) saveAboutKT();
    if (profile?.isECT) saveAboutECT();
    if (profile?.isSA) saveAboutSA();
  };

  const handleSubmitWrapUp = () => {
    setSaveLoading(false);
  };
  useEffect(() => {
    if (!aboutUpdating && saveLoading) {
      if (isEmptyOrNull(aboutUpdatingError)) {
        toastSuccess();
        dispatch(
          getAbout({
            endpoint: `${apiUrl.linkApi}profile/${profile?.hrId}/about?staffType=${
              (profile?.isKT && "kt") || (profile?.isSA && "sa") || (profile?.isECT && "ect")
            }`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.profile}`
              }
            }
          })
        );
        fetchProfileCompletionData(profile?.hrId, dispatch);
      } else {
        handleSubmitWrapUp();
        toastError();
      }
    }
  }, [aboutUpdating]);

  useEffect(() => {
    if (!aboutMeLoading && saveLoading) {
      handleSubmitWrapUp();
      onClose();
    }
  }, [aboutMeLoading]);

  // Find Practice area id based on name

  const findId = (val, typeId) => {
    sortedPracticeAreaOptions.forEach((item) => {
      if (item.value === val) {
        typeId(item.key || "");
      }
    });
  };

  // Change Values when input/picker values cahnges
  // set form dirty

  const changeValue = (val, type, typeId) => {
    setIsFormChanged(true);
    setIsDirty(true);
    type(val?.name || val || null);
    findId(val, typeId);
  };

  const changeValuePikcer = (val, type, typeHrId) => {
    setIsFormChanged(true);
    setIsDirty(true);
    type(val?.name || val || null);
    typeHrId(val?.hrid || null);
  };

  const changeTopicPickerValue = (val, setTopicName, setTopicId) => {
    setIsFormChanged(true);
    setIsDirty(true);
    setTopicName(val?.topicName || val?.name || val || null);
    setTopicId(val?.id || val?.topicId || null);
  };

  const ktPAChanged = (e, { value }) => {
    changeValue(value, setKTPATopicName, setKTPATopicID);
  };

  const ectPAChanged = (e, { value }) => {
    changeValue(value, setPracticeAreaEct, setPracticeAreaEctID);
  };

  const SubtopicTaxonomy = () => (
    <TaxonomySelector
      placeholder="Search Sub Topics"
      endpoint={apiUrl.smartLogicApi}
      tbdb="AboutMe"
      headingList={{ 0: "Sub Topics" }}
      headingPos={[0, 1]}
      trigger={
        <TopicPicker
          name="kt-subTopicId"
          currentValue={ktSubTopicName}
          readOnly={!canEditKT}
          callback={() => {
            setIsDirty(true);
            setKTSubTopicID(null);
            setKTSubTopicName("");
          }}
        />
      }
      cbOnSelection={(term) => {
        changeTopicPickerValue({ id: term?.term?.id, name: term?.term?.name }, setKTSubTopicName, setKTSubTopicID);
      }}
      disabledLevelStartIndex={-1}
      parentTermId={ktPrimaryTopicID}
      hideSearch
    />
  );

  // logic to add check for avoiding duplicate PA values
  useEffect(() => {
    if (profile?.isSA) {
      setPracticeAreaOptionsTwo((prevState) => {
        const oldState = [...prevState];
        const newState = decoratePAOptions(oldState, practiceAreasOneID);
        return newState;
      });
      setPracticeAreaOptionsOne((prevState) => {
        const oldState = [...prevState];
        const newState = decoratePAOptions(oldState, practiceAreasTwoID);
        return newState;
      });
    }
  }, [practiceAreasOneID, practiceAreasTwoID]);

  return (
    <>
      <Modal
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        centered={false}
        closeOnDimmerClick={false}
        className={`${openWarnPopup ? "d-none" : ""} custom-modal`}
      >
        <Modal.Header className="heading pl-3 pr-3">{ABOUT.EditAbout}</Modal.Header>
        <Modal.Content className="pl-0 pr-0 pt-0 position-relative">
          <>
            {/* Save loader scree */}
            {saveLoading ? (
              <Segment className="edit-about__loadingSegment">
                <Dimmer active inverted>
                  <Loader size="large">{COMMON.Saving}</Loader>
                </Dimmer>
              </Segment>
            ) : null}
            {/* Position block */}
            <div className="pl-3 pr-3 edit-about__block">
              <div className="edit-about__profileList">
                <div className="fs-12 mb-2">
                  <Icon name="info circle" className="edit-about__profileList__icon" />
                  {ABOUT.ContactHR}
                </div>

                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={ccoData?.length ? 4 : 16} tablet={16} mobile={16}>
                      <p>{ABOUT.Position}</p>
                      <p className="mb-0">
                        <strong>{peopleData?.jobPosition}</strong>
                      </p>
                    </Grid.Column>

                    {!!ccoData?.length && canEditCCO && (
                      <Grid.Column computer={12} tablet={16} mobile={16} data-testid="cco-block">
                        <p>{ABOUT.CCO}</p>
                        <p className="mb-0">
                          <strong>{ccoData.join(`, `)}</strong>
                        </p>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </div>
            </div>
            {/* Sr. Adv block */}
            {profile?.isSA && (
              <div className="pl-3 pr-3 edit-about__block" data-testid="sr-advisor-block">
                <h5 className="text-uppercase mb-1">{SRADVISOR.TITLE}</h5>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{SRADVISOR.PA1}</b>
                      </p>
                      <Select
                        data-testid="pa1-sr-adv"
                        fluid
                        value={practiceAreasOneID}
                        placeholder="Select Practice Area"
                        options={practiceAreaOptionsOne}
                        onChange={(e, { value }) => changeValue(value, setPracPracticeAreasOne, setPracticeAreasOneID)}
                        name="pa1"
                        disabled={!canEditSA}
                      />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{SRADVISOR.PA2}</b>
                      </p>
                      <Select
                        data-testid="pa2-sr-adv"
                        fluid
                        value={practiceAreasTwoID}
                        placeholder="Select Practice Area"
                        options={practiceAreaOptionsTwo}
                        onChange={(e, { value }) => changeValue(value, setPracPracticeAreasTwo, setPracticeAreasTwoID)}
                        name="pa2"
                        disabled={!canEditSA}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{SRADVISOR.Sponsor1}</b>
                      </p>
                      <PeoplePicker
                        currentValue={sponsorOne}
                        callback={(value) => changeValuePikcer(value, setSponsorOne, setSponsorOneHrID)}
                        hrid={sponsorOneHrID}
                        readOnly={!canEditSA}
                      />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{SRADVISOR.Sponsor2}</b>
                      </p>
                      <PeoplePicker
                        currentValue={sponsorTwo}
                        callback={(value) => changeValuePikcer(value, setSponsorTwo, setSponsorTwoHrID)}
                        hrid={sponsorTwoHrID}
                        readOnly={!canEditSA}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
            {/* ECT block */}
            {profile?.isECT && (
              <div className="pl-3 pr-3 edit-about__block" data-testid="ect-block">
                <h5 className="text-uppercase mb-1">{ECT.Title}</h5>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{ECT.Sponsor}</b>
                      </p>
                      <PeoplePicker
                        name="sponsor-ect"
                        currentValue={ectSponsor}
                        callback={(value) => changeValuePikcer(value, setEctSponsor, setEctSponsorHrId)}
                        hrid={ectSponsorHrId}
                        readOnly={!canEditEct}
                      />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{ECT.SponsorPA}</b>
                      </p>
                      <Select
                        data-testid="pa-ect"
                        fluid
                        value={practiceAreaEctID}
                        placeholder={ECT.SponsorPAPlaceholderText}
                        options={getEctPracticeAreaOptions()}
                        onChange={ectPAChanged}
                        name="pa-ect"
                        disabled={!canEditEct}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{ECT.PrimaryTopic}</b>
                      </p>
                      <TaxonomySelector
                        placeholder="Search Primary Topics"
                        endpoint={apiUrl.smartLogicApi}
                        tbdb="AboutMe"
                        headingList={{ 0: "Primary Topics" }}
                        headingPos={[0, 1]}
                        trigger={
                          <TopicPicker
                            name="ect-PrimaryTopicId"
                            readOnly={!canEditEct}
                            currentValue={ectSponsorTopic}
                            callback={() => {
                              setIsDirty(true);
                              setIsFormChanged(true);
                              setEctSponsorTopicId(null);
                              setEctSponsorTopic("");
                            }}
                          />
                        }
                        cbOnSelection={(term) => {
                          changeTopicPickerValue(
                            { id: term?.term?.id, name: term?.term?.name },
                            setEctSponsorTopic,
                            setEctSponsorTopicId
                          );
                        }}
                        disabledLevelStartIndex={0}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
            {/* Knowledge Expert */}
            {profile?.isKT && (
              <div className="pl-3 pr-3 edit-about__block" data-testid="kt-block">
                <h5 className="text-uppercase mb-1">{KT.Title}</h5>
                <div className="fs-12 mb-2">
                  <Icon name="info circle" className="edit-about__profileList__icon" />
                  {`${KT.ContactSC1} `}
                  <a className="link-textcolor" href={KT.ContactSCEmail}>
                    {KT.ContactSC2}
                  </a>
                  {` ${KT.ContactSC3}`}
                </div>
                <Grid>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{KT.PracticeArea}</b>
                      </p>
                      <Select
                        data-testid="pa-kt"
                        fluid
                        value={ktPATopicID}
                        placeholder="Select Practice Area"
                        options={sortedPracticeAreaOptions}
                        onChange={ktPAChanged}
                        name="a-kt"
                        disabled={!canEditKT}
                      />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{KT.PrimaryTopic}</b>
                      </p>

                      <TaxonomySelector
                        placeholder="Search Primary Topics"
                        endpoint={apiUrl.smartLogicApi}
                        tbdb="AboutMe"
                        headingList={{ 0: "Primary Topics" }}
                        headingPos={[0, 1]}
                        trigger={
                          <TopicPicker
                            name="kt-PrimaryTopicId"
                            readOnly={!canEditKT}
                            currentValue={ktPrimaryTopicName}
                            callback={() => {
                              setIsDirty(true);
                              setKTPrimaryTopicID(null);
                              setKTPrimaryTopicName("");
                            }}
                          />
                        }
                        cbOnSelection={(term) => {
                          if (!ktPrimaryTopicID && (ktSubTopicID || ktSubTopicName))
                            changeTopicPickerValue("", setKTSubTopicName, setKTSubTopicID);
                          changeTopicPickerValue(
                            { id: term?.term?.id, name: term?.term?.name },
                            setKTPrimaryTopicName,
                            setKTPrimaryTopicID
                          );
                        }}
                        disabledLevelStartIndex={0}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                      <p className="mb-h">
                        <b>{KT.SubTopic}</b>
                      </p>
                      {!saveLoading && <SubtopicTaxonomy />}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            )}
          </>
        </Modal.Content>
        <Modal.Actions className="footer pl-3 pr-3">
          <Button basic secondary onClick={checkDirty}>
            {COMMON.Cancel}
          </Button>

          <div
            data-tooltip={profile?.isECT && !isdirty && isFormChanged ? `${ABOUT.DisableButtonToolTip}` : null}
            className="tootltipMessage"
          >
            <Button primary type="submit" disabled={!isdirty} onClick={handleSubmit}>
              {COMMON.Save}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
      <Modal open={openWarnPopup} centered closeOnDimmerClick={false} className="custom-modal-small">
        <Modal.Content className="text-right p-1">
          <div className="warningpopupContent">
            <IconC name="warning" size={32} className="mr-1" /> {ABOUT.Warnmesg}
          </div>
          <Button
            data-testid="cancel"
            basic
            className="button-sm mr-1"
            secondary
            onClick={() => setOpenWarnPopup(false)}
          >
            {COMMON.No}
          </Button>
          <Button primary className="button-sm" type="submit" onClick={onClose}>
            {COMMON.Yes}
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};

EditAbout.defaultProps = {
  profile: {},
  permissions: {},
  open: false,
  onOpen: () => {},
  onClose: () => {}
};

EditAbout.propTypes = {
  profile: PropTypes.shape(),
  permissions: PropTypes.shape(),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

export default EditAbout;
