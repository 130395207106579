import { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "semantic-ui-react";
import qs from "qs";
import PropTypes from "prop-types";
import LABEL from "constants/label";
import EditProfile from "components/EditProfile";
import { getResume } from "redux/actions/resume";
import sendEventData from "analytics/analytics";
import { getProposalBios } from "redux/actions/proposalbios";
import { downloadStart } from "redux/actions/download";
import { getLanguages } from "redux/actions/language";
import { TRIGGERS, EVENTS } from "analytics/constants";
import EditButton from "components/common/EditButton";
import HowCanIbeMoreFindable from "components/HowCanIbeMoreFindable";
import CONFIG from "constants/config";
import { PROFICIENCYSCORES } from "helpers/constants";
import useMakeLinkedInQuery from "customHooks/useMakeLinkedInQuery";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";
import { InlineList, Icon, Loading } from "../common";
import { List, Text } from "../common/Limiter";
import LanguagesSummary from "../LanguageProficiencies";
import { createParamsObject, formatDateMonthYear, useQuery } from "../../helpers/utils";
import { getExtension, sortProposalsByDate } from "./helper/utils";
import styles from "./styles.module.scss";

const { PROFILE_SUMMARY, COMMON } = LABEL;

/**
 * ProfileSummary component
 *
 * @param  {string} className
 * @param  {object} user - contains all the data for a given user
 *
 * @return summary component with description, personal interests, languages known, resumes and bios
 *
 * @example
 *
 * <ProfileSummary user={user}  />
 */

const ProfileSummary = ({ user, className, languageData, isLoading, isEditable, loggedInUserHrId }) => {
  const dispatch = useDispatch();
  const { apiUrl, xApiKey } = CONFIG;
  const { profileProposalBios = [], profileResumes, languages } = useSelector((state) => state);
  const languageOption = languages?.languageList;
  const profileResume = profileResumes[0];
  const { summary = "", personalInterest = "" } = user;
  let showProposals = true;
  const modalState = false;
  const [sortedLanguages, setSortedLanguages] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery(location?.search);

  const { updateQueryParams, deleteQueryParam } = useUpdateQueryParams(location, history);

  useEffect(() => {
    if (languageData?.length > 1) {
      setSortedLanguages(
        languageData
          ?.map((language) => {
            return { ...language, sortOrder: PROFICIENCYSCORES[language?.fluencyPresent?.toLowerCase() || "none"] };
          })
          .sort((a, b) => b?.sortOrder - a?.sortOrder)
      );
      return;
    }
    setSortedLanguages(languageData);
  }, [languageData]);

  const urlQueryParams = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const applicationSource = urlQueryParams[COMMON.SOURCE_NAME];
  const [sourceApplicationName, setSourceApplicationName] = useState("");

  useEffect(() => {
    if (applicationSource) {
      const source = `${applicationSource ? `&source=${applicationSource}` : ""}`;
      setSourceApplicationName(source);
    }
  }, []);
  useEffect(() => {
    dispatch(
      getLanguages({
        endpoint: `${apiUrl.linkApi}profile/ref/language`,
        config: {
          headers: {
            "x-api-key": `${xApiKey.profile}`
          }
        }
      })
    );
  }, []);

  const section = query.get("edit");

  useEffect(() => {
    if (user?.hrId) {
      dispatch(
        getProposalBios({
          endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/proposalbio`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      dispatch(
        getResume({
          endpoint: `${apiUrl.linkApi}profile/${user?.hrId}/resume`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [user?.hrId]);

  const resume = useMemo(() => {
    if (profileResume?.resumeId) {
      return {
        resumeId: profileResume?.resumeId,
        title: profileResume?.title,
        filePath: profileResume?.filePath,
        language: profileResume?.language,
        creationDate: profileResume?.creationDate,
        lastModifiedBy: profileResume?.createdBy,
        isDeleted: profileResume?.isDeleted,
        type: "Resume"
      };
    }

    return {};
  }, [
    profileResume?.resumeId,
    profileResume?.title,
    profileResume?.filePath,
    profileResume?.language,
    profileResume?.creationDate,
    profileResume?.createdBy,
    profileResume?.isDeleted
  ]);

  if (!resume.title && profileProposalBios?.length === 0) {
    showProposals = false;
  }
  const [open, setOpen] = useState(modalState);
  const [walkMe, setWalkMe] = useState(false);

  // edit Popup
  const openWalkmePopup = () => {
    setWalkMe(true);
  };
  const closeWalkmePopup = () => {
    setWalkMe(false);
  };
  const openEditPopup = () => {
    window.$tabName = PROFILE_SUMMARY.ProfileSummary; // Global variable for Analytics
    sendEventData(TRIGGERS.COMMON.EDIT, EVENTS.PROFILE_SUMMARY); // Analytics Event
    updateQueryParams({ ...createParamsObject(location?.search), edit: "profile-summary" });
    setOpen(true);
    closeWalkmePopup();
  };

  const closeEditPopup = () => {
    deleteQueryParam({}, "edit");
    setOpen(false);
  };

  // get personal interests array
  const getPersonalInterests = useMemo(() => {
    const title = PROFILE_SUMMARY.PersonalInterests;

    return { component: <InlineList title={title} text={personalInterest} /> };
  }, [personalInterest]);

  // generate resume and proposal bio structure

  const getResumes = useMemo(() => {
    const resumeList = [];
    // eslint-disable-next-line no-extra-boolean-cast
    const { title, resumeId, language, creationDate } = resume;
    if (title) {
      const header = PROFILE_SUMMARY.Resume;
      const trigger = TRIGGERS.PROFILE_SUMMARY.RESUME;
      const extension = getExtension(title);

      resumeList.push(
        <div className={styles["pr__item"]}>
          <div className={styles["pr__icon"]}>
            <Icon name={extension} />
          </div>
          <div className={styles["pr__content"]}>
            <button
              type="button"
              data-testid="resume"
              className={`${styles["pr__link"]} cursor-pointer d-inline-block text-align-left p-0`}
              onClick={() => {
                window.$tabName = PROFILE_SUMMARY.ProfileSummary; // Global variable for Analytics
                sendEventData(trigger, EVENTS.PROFILE_SUMMARY); // Analytics Event
                dispatch(
                  downloadStart({
                    endpoint: `${apiUrl.downloadFile}download/v2/${resumeId}/document?type=Resume&appName=Profiles`,
                    config: {
                      headers: {
                        "x-api-key": `${xApiKey.profile}`
                      }
                    }
                  })
                );
              }}
            >
              {title}
            </button>
            {}
            <InlineList
              items={[
                header,
                languageOption?.find((lang) => lang?.languageId === language)?.name,
                formatDateMonthYear(creationDate)
              ]}
              size="sm"
            />
          </div>
        </div>
      );
    }

    return { resumeList };
  }, [resume, languageOption?.length]);

  const getProposalBio = useMemo(() => {
    const proposalBiosArr = [];
    const newProposalBios = profileProposalBios.map((bio) => ({
      ...bio,
      type: PROFILE_SUMMARY.Proposal
    }));

    sortProposalsByDate(newProposalBios);

    newProposalBios.forEach((item) => {
      const { title, proposalBioId, creationDate, language } = item;
      const header = PROFILE_SUMMARY.Proposal;
      const trigger = TRIGGERS.PROFILE_SUMMARY.BIO;
      const extension = getExtension(item.title);

      proposalBiosArr.push(
        <div key={proposalBioId} className={styles["pr__item"]}>
          <div className={styles["pr__icon"]}>
            <Icon name={extension} />
          </div>
          <div className={styles["pr__content"]}>
            <button
              type="button"
              data-testid="proposalBio"
              className={`${styles["pr__link"]} cursor-pointer d-inline-block text-align-left p-0`}
              onClick={() => {
                window.$tabName = PROFILE_SUMMARY.ProfileSummary; // Global variable for Analytics
                sendEventData(trigger, EVENTS.PROFILE_SUMMARY); // Analytics Event
                dispatch(
                  downloadStart({
                    endpoint: `${apiUrl.downloadFile}download/v2/${proposalBioId}/document?type=Proposal_Bio&appName=Profiles`,
                    config: {
                      headers: {
                        "x-api-key": `${xApiKey.profile}`
                      }
                    }
                  })
                );
              }}
            >
              {title}
            </button>
            <InlineList
              items={[
                header,
                languageOption?.find((lang) => lang?.languageId === language)?.name,
                formatDateMonthYear(creationDate)
              ]}
              size="sm"
            />
          </div>
        </div>
      );
    });
    // }

    return { proposalBiosArr };
  }, [profileProposalBios, languageOption?.length]);

  useEffect(() => {
    if (user?.hrId && loggedInUserHrId) {
      if (section === "profile-summary" && isEditable) {
        setOpen(true);
      }
    }
  }, [user?.hrId, loggedInUserHrId, sourceApplicationName]);

  return (
    <>
      {(!isLoading && (
        <Card fluid className={`profile-summary ${className || ""}`}>
          <Card.Content>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                {PROFILE_SUMMARY.ProfileSummary}
                <div className={styles["summary_walkMe"]}>
                  {Number(user?.hrId) === +loggedInUserHrId && (
                    <EditButton
                      caption={COMMON.HowCanIbeMoreFindablekMe}
                      callback={openWalkmePopup}
                      imgSrc="/icons/icon-help.svg"
                    />
                  )}
                </div>
                {isEditable && <EditButton caption={COMMON.Edit} callback={openEditPopup} />}
              </div>
            </Card.Header>
          </Card.Content>

          <Card.Content>
            {summary ? (
              <div className={styles["summary__description"]}>
                <Text data={summary} limit={297} additional={getPersonalInterests.component} countDots />
              </div>
            ) : (
              <div className={styles["summary__description"]}>
                <div className={styles["summary__disabled"]}>{PROFILE_SUMMARY.NoProfileSummaryAdded}</div>
              </div>
            )}

            {!summary && getPersonalInterests && (
              <div className={styles["summary__description"]}>{getPersonalInterests.component}</div>
            )}

            <div className={styles["summary__language"]}>
              <LanguagesSummary items={sortedLanguages} />
            </div>

            <div className={styles["summary__proposal"]}>
              <h5 className={styles["summary__proposal__title"]}>{PROFILE_SUMMARY.ProposalBio}</h5>

              {showProposals ? (
                <>
                  <List items={[...getProposalBio.proposalBiosArr, ...getResumes.resumeList]} />
                </>
              ) : (
                <div className={styles["summary__disabled"]}>{PROFILE_SUMMARY.NoProposalBioAdded}</div>
              )}
            </div>
          </Card.Content>
        </Card>
      )) || <Loading title="Profile Summary" className="mobile-order-2 profile-summury-card profile-summary" />}
      {open && (
        <EditProfile
          open={open}
          onClose={closeEditPopup}
          onOpen={openEditPopup}
          user={user}
          resume={resume}
          proposalBios={profileProposalBios}
        />
      )}
      {walkMe && (
        <HowCanIbeMoreFindable
          open={walkMe}
          onClose={closeWalkmePopup}
          onOpen={openWalkmePopup}
          openEdit={openEditPopup}
        />
      )}
    </>
  );
};

ProfileSummary.defaultProps = {
  className: "",
  user: {},
  isEditable: false,
  isLoading: true,
  languageData: []
};

ProfileSummary.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    backgroundDescription: PropTypes.string,
    hrId: PropTypes.number,
    personalInterests: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)),
    proposalBios: PropTypes.arrayOf(PropTypes.object),
    preBCGResumeId: PropTypes.number,
    preBCGResumeFileName: PropTypes.string,
    preBCGResumeImageName: PropTypes.string,
    preBCGResumeLanguage: PropTypes.string,
    preBCGResumeLastModifiedTime: PropTypes.string,
    preBCGResumeLastModifiedBy: PropTypes.string,
    summary: PropTypes.string,
    personalInterest: PropTypes.string
  }),
  isEditable: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedInUserHrId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  languageData: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ProfileSummary;
